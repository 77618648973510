import React, { useRef, useEffect, useState } from "react";
import "./HomeContent.scss";

// A simple typewriter component that types then erases the provided text.
const Typewriter = ({ text, speed = 150, pause = 1000 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    let timeout;

    if (typing) {
      if (displayedText.length < text.length) {
        // Continue typing: add one more character.
        timeout = setTimeout(() => {
          setDisplayedText(text.slice(0, displayedText.length + 1));
        }, speed);
      } else {
        // Once full text is typed, pause then switch to deleting.
        timeout = setTimeout(() => {
          setTyping(false);
        }, pause);
      }
    } else {
      if (displayedText.length > 0) {
        // Continue deleting: remove one character.
        timeout = setTimeout(() => {
          setDisplayedText(text.slice(0, displayedText.length - 1));
        }, speed);
      } else {
        // Once text is fully deleted, pause then switch to typing.
        timeout = setTimeout(() => {
          setTyping(true);
        }, pause);
      }
    }

    return () => clearTimeout(timeout);
  }, [displayedText, typing, text, speed, pause]);

  return <span>{displayedText}</span>;
};

const HomeContent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    let width = window.innerWidth;
    let height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;

    // Default pointer coordinates (center of the screen)
    let pointerX = width / 2;
    let pointerY = height / 2;

    // Update pointer coordinates on mouse move.
    const handleMouseMove = (event) => {
      pointerX = event.clientX;
      pointerY = event.clientY;
    };

    // Update pointer coordinates on touch move for mobile.
    const handleTouchMove = (event) => {
      if (event.touches && event.touches[0]) {
        pointerX = event.touches[0].clientX;
        pointerY = event.touches[0].clientY;
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove, { passive: true });

    // Resize the canvas on window resize.
    const handleResize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      canvas.width = width;
      canvas.height = height;
    };
    window.addEventListener("resize", handleResize);

    // Animation loop variables.
    let time = 0;
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.02;

      // Clear canvas with a dark background.
      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, width, height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = "#444";

      // Draw multiple sine waves for a layered effect.
      const numberOfWaves = 3;
      for (let i = 0; i < numberOfWaves; i++) {
        ctx.beginPath();
        const verticalOffset = (i - 1) * 20;
        for (let x = 0; x <= width; x += 5) {
          const amplitude = 50 + (pointerY / height) * 50;
          const frequency = 0.01;
          const phase = time + (pointerX / width) * Math.PI * 2 + i * 0.5;
          const y = height / 2 + Math.sin(x * frequency + phase) * amplitude + verticalOffset;
          if (x === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
        ctx.stroke();
      }
    };

    animate();

    // Cleanup event listeners on component unmount.
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="home-content">
      {/* Wave animation canvas */}
      <canvas ref={canvasRef} className="wave-canvas" />

      <div className="home-text-container">
        <div className="home-text-main">
          <h1>Hi, we're</h1>
          <h2 className="animated-typewriter">
            <Typewriter text="Spinnin." speed={150} pause={1000} />
          </h2>
          {/* <p>
            We are a music discovery platform celebrating the freshest sounds and timeless
            classics in dance, house, garage, and beyond. From emerging artists to the pioneers
            who shaped these genres, we bring you closer to the music that moves you. Join us in
            keeping the rhythm alive and the vibes soulful.
          </p> */}
          {/* Centered image absolutely positioned over the wave background */}
          <img className="center-image" src="assets/spinnin-beans.png" alt="Center" />
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
